<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="외부인력용 QR코드 출력" v-show="completeBtnEditable || (editable && disabled)" icon="save_alt" @btnClicked="btnQrCode" />
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            label="LBLREMOVE" 
            icon="delete" 
            @btnClicked="deleteInvestigation" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="investigation"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveInvestigation"
            @btnCallback="saveInvestigationCallback" />
          <c-btn 
            v-show="completeBtnEditable" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="investigation"
            mappingType="PUT"
            label="유해요인조사 요청" 
            icon="check"
            @beforeAction="completeInvestigation"
            @btnCallback="completeInvestigationCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-text
            :required="true"
            :disabled="disabled"
            :editable="editable"
            label="조사계획명"
            name="investigationPlanName"
            v-model="investigation.investigationPlanName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            type="year"
            default="today"
            label="LBLYEAR"
            name="year"
            v-model="investigation.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :range="true"
            label="조사기간"
            name="period"
            v-model="investigation.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="investigation"
            deptValue="deptCd"
            type="dept_user"
            label="LBLMANAGER"
            name="userId"
            v-model="investigation.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-dept-multi 
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            :parentCheckDepts="investigation.investigationDeptCd" 
            label="조사부서" 
            name="investigationDeptCd" 
            v-model="investigation.investigationDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="조사기관"
            name="agency"
            v-model="investigation.agency">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-plant 
            :required="true"
            :disabled="isOld"
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="investigation.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="2"
            label="비고"
            name="remark"
            v-model="investigation.remark">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <c-table
            ref="gridworker"
            title="설문 대상자"
            :columns="gridworker.columns"
            :gridHeight="gridworker.height"
            :data="investigation.muscleWorkers"
            :filtering="false"
            :columnSetting="false"
            :editable="editable&&!disabled"
            :usePaging="false"
            :isExcelDown="false"
            :isFullScreen="false"
            selection="multiple"
            rowKey="heaMuscleWorkerId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addMuscleWorker" />
                <c-btn 
                  v-if="editable&&!disabled" 
                  :disabled="!investigation.muscleWorkers || investigation.muscleWorkers.length === 0"
                  label="LBLREMOVE" 
                  icon="remove" 
                  @btnClicked="deleteMuscleWorker" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="LBLATTACHMENT">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'investigationPlanInfo',
  props: {
    investigation: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        plantCd: '',  // 사업장코드
        investigationPlanName: '',  // 조사계획명
        year: '',  // 심사년도
        heaInvestigationStepCd: '',  // 진행상태
        investigationStartDate: '',  // 조사시작일
        investigationEndDate: '',  // 조사종료일
        period: [],
        deptCd: '',  // 담당자 부서
        userId: '',  // 담당자 id
        investigationDeptCd: '',  // 조사부서
        agency: '',  // 조사기관
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sops: [], // 단위작업
        sopTable: [], // 작업분류표
        muscleWorkers: [],
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'HEA_INVESTIGATION_PLAN',
        taskKey: '',
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridworker: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLNAME',
            align: 'center',
            sortable: false,
          },
        ],
        height: '400px'
      },
      editable: true,
      isSave: true,
      isComplete: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      deleteWorkerUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '65%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    completeBtnEditable() {
      return this.editable 
        && !this.disabled 
        && this.isOld
    },
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 350) + 'px' : '500px'
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.insert.url
      this.deleteUrl = transactionConfig.hea.muscleSystem.investigationPlan.delete.url
      this.completeUrl = transactionConfig.hea.muscleSystem.investigationPlan.complete.url
      this.deleteWorkerUrl = transactionConfig.hea.muscleSystem.investigationPlan.worker.delete.url
      // code setting
      // list setting
    },
    saveInvestigation() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.hea.muscleSystem.investigationPlan.insert.url
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.investigation.regUserId = this.$store.getters.user.userId
              this.investigation.chgUserId = this.$store.getters.user.userId
              this.$set(this.investigation, 'investigationStartDate', this.investigation.period[0])
              this.$set(this.investigation, 'investigationEndDate', this.investigation.period[1])

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveInvestigationCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaInvestigationPlanId', _result.data)
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$emit('getDetail')
    },
    completeInvestigation() {      
      /**
       * 1. 단위작업이 하나 이상인지?
       * 2. 단위작업별 부담작업이 하나 이상 등록되었는지?
       * 3. 부담작업 조사가 이루어졌는지 확인
       * (* 부담작업의 평가결과가 O인 것이 있는지? (일단은 체크하지 않음))
       */
      if (!this.investigation.sops || this.investigation.sops.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '단위작업을 하나 이상 등록하시기 바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        let _data = this.$_.filter(this.investigation.sops, (sop) => {
          return (!sop.heavyWorks || sop.heavyWorks.length === 0)
        })
        if (_data && _data.length > 0) {
          let _sops = this.$_.map(_data, 'sopName');
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: `부담작업이 입력되지 않은 단위작업이 존재합니다.\n(* ${_sops.toString()})`,
            type: 'warning', // success / info / warning / error
          });
          return;
        }

        let _investigationData = this.$_.filter(this.investigation.sops, (sop) => {
          return this.$_.findIndex(sop.heavyWorks, (heavyWork) => {
            return (!heavyWork.detailJobContents || !heavyWork.assessResult)
          }) > -1
        })
        if (_investigationData && _investigationData.length > 0) {
          let _sops = this.$_.map(_investigationData, 'sopName');
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: `부담작업 중 조사가 되지 않은 단위작업이 존재합니다.\n(* ${_sops.toString()})`,
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '유해요인 조사하시겠습니까?\n(* 유해요인 조사 메뉴에서 이어서 진행하시면 됩니다.)',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.investigation.chgUserId = this.$store.getters.user.userId
              this.$set(this.investigation, 'investigationStartDate', this.investigation.period[0])
              this.$set(this.investigation, 'investigationEndDate', this.investigation.period[1])

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeInvestigationCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    deleteInvestigation() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaInvestigationPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addMuscleWorker() {
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.title = 'LBLUSER';
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.investigation.muscleWorkers) this.investigation.muscleWorkers = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.investigation.muscleWorkers, { userId: item.userId }) === -1) {
            this.investigation.muscleWorkers.push({
              editFlag: 'C',
              heaMuscleWorkerId: uid(),  // 근골격계 단위작업 작업자 일련번호
              heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId,  // 근골격계 조사계획 일련번호
              userId: item.userId,
              userName: item.userName,
              deptName: item.deptName,
              jobName: item.jobName,
            });
          }
        });
      }
    },
    deleteMuscleWorker() {
      let selectData = this.$refs['gridworker'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteWorkerUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.investigation.muscleWorkers = this.$_.reject(this.investigation.muscleWorkers, item);
          })
          this.$refs['gridworker'].$refs['compo-table'].clearSelection();
          // this.getTeams();
        },);
      }
    },
    btnQrCode() {
      this.popupOptions.title = "근골격계 조사계획"; // 설비 QR코드
      this.popupOptions.param = {
        selectData: this.investigation,
      };
      this.popupOptions.target = () => import(`${"./investigationQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '600px';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>